'use client'

import React from 'react'
import {useTranslations} from 'next-intl'

import {Locales} from '../../../config/locale.config'
import {usePathname, useRouter} from '../../../app/i18n/routing'
import {useAppCtx} from '../../../contexts/appCtx'
import Select from '../../elements/Select'


export default function LocaleSelect({...props}) {
  const t = useTranslations()
  const router = useRouter()
  const pathname = usePathname()

  const {locale} = useAppCtx()

  const localeSelections = React.useMemo(() => Locales
    .map(locale => ({
      key: locale,
      label: t(`locales.${locale}`),
      value: locale,
    })), [t])

  const onLocaleChange = React.useCallback((value) => {
    (async function () {
      // 若手動切換語言就記著使用者的選擇
      // setCookie('NEXT_LOCALE', value, {sameSite: 'strict'})
      router.replace(pathname, {locale: value})
    })()
  }, [router, pathname])

  React.useEffect(() => {
    if (Locales.indexOf(locale) === -1) {
      onLocaleChange(Locales[0])
    }
  }, [locale, onLocaleChange])

  return (
    <Select
      currentValue={locale}
      selections={localeSelections}
      onSelectionChange={onLocaleChange}
      {...props}
    />
  )
}

