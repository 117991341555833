'use client'

import React from 'react'
import {useTranslations} from 'next-intl'
import {css, useTheme} from '@emotion/react'

import SettingsControl from '../../../../../components/modules/SettingsControl'

export default function HomeMobileLayout({children}) {
  const theme = useTheme()
  const styles = {
    settings: css`
      position: fixed;
      top: 12px;
      right: 12px;
    `,

    header: css`
      display: flex;
      justify-content: space-between;
      align-items: center;

      margin-bottom: 8px;
    `,

    title: css`
      color: ${theme.palette.onPrimary.dark};
      font-size: 1.5em;
      font-weight: 700;
      margin: unset;
    `,

    description: css`
      margin-bottom: 16px;
      font-size: 1.2rem;
    `,

    content: css`
      flex: 1;
      position: relative;
    `,
  }

  const t = useTranslations()

  return (
    <>
      {/* 設定不需要爬蟲抓取 */}
      <div css={styles.settings} data-nosnippet={true}>
        <SettingsControl icon/>
      </div>

      <header id="header">
        <div css={styles.header}>
          <h1 id="title" css={styles.title}>{t(`home.title`)}</h1>
        </div>
        <div css={styles.description}>{t('home.description')}</div>
      </header>
      {/* 內容不需要爬蟲抓取 */}
      <div css={styles.content} data-nosnippet={true}>
        {children}
      </div>
    </>
  )
}
